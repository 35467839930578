import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const CART_IMPORT_EXPORT_FEATURE = 'cartImportExport';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ImportExportRootModule {
  static {
    this.ɵfac = function ImportExportRootModule_Factory(t) {
      return new (t || ImportExportRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ImportExportRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        featureModules: {
          [CART_IMPORT_EXPORT_FEATURE]: {
            cmsComponents: ['ImportExportOrderEntriesComponent', 'ImportOrderEntriesComponent', 'ExportOrderEntriesComponent']
          }
        }
      })]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImportExportRootModule, [{
    type: NgModule,
    args: [{
      providers: [provideDefaultConfig({
        featureModules: {
          [CART_IMPORT_EXPORT_FEATURE]: {
            cmsComponents: ['ImportExportOrderEntriesComponent', 'ImportOrderEntriesComponent', 'ExportOrderEntriesComponent']
          }
        }
      })]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CART_IMPORT_EXPORT_FEATURE, ImportExportRootModule };
