import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { map } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import packageInfo from '@bdhm/bgs-common-ui-lib/package.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config:any;
  booleanList: any = ["production", "adobeCommonEnabled", "OhpCardView"];
  constructor(private http: HttpClient, private occEndpointService: OccEndpointsService) { }

  initializeConfig() {
    return this.fetchConfig().pipe(
      map((response: any) => {
      const encryptedText = response.config;
      const decryptedData = CryptoJS.enc.Base64.parse(encryptedText.substring(64)).toString(CryptoJS.enc.Utf8);
      this.config = JSON.parse(decryptedData);
      if(this.config){
        Object.keys(this.config?.['myboeingfleet']).forEach(key => {
          if(key){
            if (this.booleanList.includes(key )) {
              this.config['myboeingfleet'][key] = this.config?.['myboeingfleet'][key] === "true"; // Convert "true" string to boolean true, and "false" to boolean false
            }
            if(key == 'version'){
              this.config['myboeingfleet'][key] = packageInfo.version;
            }
          }
        });
      }
       return this.config;
      })
    );
  }

  fetchConfig() {
    return this.http.get(this.occEndpointService.buildUrl("configuration"));
  }

  getConfig(code?: string) {
    return code ? this.config?.['myboeingfleet'][code] : this.config?.['myboeingfleet'];
    }
  
}

