var loginForm = {
	forgotPassword: "Forgot password?",
	signIn: "Sign In",
	register: "Register",
	dontHaveAccount: "Don’t have an account?",
	guestCheckout: "Guest Checkout",
	emailAddress: {
		label: "Email address",
		placeholder: "Enter Email Address"
	},
	password: {
		label: "Password",
		placeholder: "Enter Your Password"
	},
	wrongEmailFormat: "This is not a valid email format."
};
var verificationTokenForm = {
	createVerificationToken: "Verification code sent to {{target}}. Please check and enter the code.",
	needInputCredentials: "Please enter your credentials to continue.",
	sendRateLime: "in {{waitTime}} seconds",
	resend: "Resend",
	verificationCode: {
		label: "Verification Code",
		placeholder: "Enter Verification Code"
	},
	noReceiveCode: "Didn't receive the code?",
	verify: "Verify",
	back: "Back",
	tokenInputHint: "You can request a new code. A timer starts indicating the seconds left until you can resend your request."
};
var verificationTokenDialog = {
	title: "Didn’t receive the code",
	noReceiveCode: "If you didn't receive the code, the reasons might be:",
	contentLine1: "1. The email hasn't come yet.",
	contentLine2: "2. The received email has been treated as junk email.",
	contentLine3: "3. Either the email address or the password you entered is incorrect.",
	close: "Close"
};
var miniLogin = {
	userGreeting: "Hi, {{name}}",
	signInRegister: "Sign In / Register"
};
var myAccountV2User = {
	signOut: "Sign Out"
};
var userAccount = {
	loginForm: loginForm,
	verificationTokenForm: verificationTokenForm,
	verificationTokenDialog: verificationTokenDialog,
	miniLogin: miniLogin,
	myAccountV2User: myAccountV2User
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    userAccount,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const userAccountTranslations = {
    en,
};
const userAccountTranslationChunksConfig = {
    userAccount: [
        'loginForm',
        'verificationTokenForm',
        'verificationTokenDialog',
        'miniLogin',
        'myAccountV2User',
    ],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { userAccountTranslationChunksConfig, userAccountTranslations };

