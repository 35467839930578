var savedCartDetails = {
	cartName: "Name",
	cartDescription: "Description",
	cartId: "ID",
	dateSaved: "Date Saved",
	items: "Items",
	quantity: "Quantity",
	total: "Total",
	editSavedCart: "Edit Saved Cart",
	deleteSavedCart: "Delete Saved Cart",
	addSelectedItems: "Add selected items",
	emptyCartItems: "Empty Cart Items"
};
var savedCartList = {
	savedCarts: "Saved Carts ({{count}})",
	cartName: "Name",
	cartId: "Id",
	dateSaved: "Date Saved",
	cartDescription: "Description",
	quantity: "Qty",
	quantityFull: "Quantity",
	total: "Total",
	actions: "Actions",
	makeCartActive: "Make cart active",
	notFound: "No Saved Carts Found",
	swapCartNoActiveCart: "Existing cart is activated by {{cartName}} successfully.",
	swapCartWithActiveCart: "Existing cart is activated by {{cartName}} successfully. Your previous items were saved in a cart {{previousCartName}}."
};
var savedCartCartPage = {
	messages: {
		cartSaved: "Your cart items have been successfully saved for later in the \"{{cartName}}\" cart"
	}
};
var savedCartDialog = {
	saveForLater: "Save For Later",
	itemsSavedForLater: "All of the items in your cart will be saved for later",
	savedCartName: "Saved Cart Name",
	savedCartDescription: "Saved Cart Description",
	optional: "optional",
	charactersLeft: "characters left: {{count}}",
	cancel: "Cancel",
	save: "Save",
	restore: "Restore",
	followingCartDelete: "The following saved cart will be deleted",
	followingCartRestore: "The following saved cart will be restored as the active cart",
	"delete": "Delete",
	deleteCartSuccess: "Cart Deleted Successfully",
	editCartSuccess: "Cart Edited Successfully",
	editSavedCart: "Edit Saved Cart",
	deleteSavedCart: "Delete Saved Cart",
	restoreSavedCart: "Restore Saved Cart",
	name: "Name",
	id: "ID",
	description: "Description",
	quantity: "QTY",
	quantityFull: "Quantity",
	total: "Total",
	keepCopySavedCart: "Keep a copy of this cart in the saved carts list",
	defaultCloneCartName: "Copy of {{name}}",
	nameOfCloneCart: "Name of copied cart"
};
var addToSavedCart = {
	savedCarts: "Saved carts",
	saveCartForLater: "Save cart for later"
};
var savedCart = {
	savedCartDetails: savedCartDetails,
	savedCartList: savedCartList,
	savedCartCartPage: savedCartCartPage,
	savedCartDialog: savedCartDialog,
	addToSavedCart: addToSavedCart
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    savedCart,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const savedCartTranslations = {
    en,
};
// expose all translation chunk mapping for savedCart feature
const savedCartTranslationChunksConfig = {
    savedCart: [
        'savedCartDetails',
        'savedCartList',
        'savedCartCartPage',
        'savedCartDialog',
        'addToSavedCart',
    ],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { savedCartTranslationChunksConfig, savedCartTranslations };

