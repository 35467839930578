import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BgsCommonUiLibModule } from '@bdhm/bgs-common-ui-lib';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { provideConfig } from "@spartacus/core";
import { AepModule } from '@spartacus/tracking/tms/aep';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { environment } from "src/environments/environment";
import { AppComponent } from "./app.component";
import { SpartacusModule } from './spartacus/spartacus.module';
import { ReqHeaderIntercepterService } from "../services/req-header-intercepter.service";
import { AppRoutingModule, NavigationEvent } from "@spartacus/storefront";
import { ConfigService } from "src/services/config.service";

export function loadConfig(configService:ConfigService){
  return ()=> configService.initializeConfig();
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BgsCommonUiLibModule,
    SpartacusModule,
    BaseTmsModule.forRoot(),
    GtmModule,
    AepModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReqHeaderIntercepterService,
     multi: true
   },
   provideConfig({
     tagManager: {
       gtm: {
         gtmId: environment.gtmId,
         events: [NavigationEvent],
       }
     },
   } as TmsConfig),
   provideHttpClient(withFetch(), withInterceptorsFromDi()),
   provideHttpClient(withFetch(), withInterceptorsFromDi()),
   provideHttpClient(withFetch(), withInterceptorsFromDi()),
   provideHttpClient(withFetch(), withInterceptorsFromDi()),
   provideHttpClient(withFetch(), withInterceptorsFromDi()),
   provideHttpClient(withFetch(), withInterceptorsFromDi()),
   {
     provide: APP_INITIALIZER,
     useFactory:loadConfig,
     deps:[ConfigService],
     multi: true
    },
   {
     provide: 'Environment',
     useFactory: (configService: ConfigService) => configService.getConfig(),
     deps: [ConfigService],
   },
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
