import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, OccConfig, PageMetaResolver, provideConfig, SiteContextConfig } from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { defaultCmsContentProviders, LayoutConfig, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { getBaseUrl } from 'src/environments/environment';
import { CustomPageMetaResolver } from '../../services/customPage.resolver';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    CustomPageMetaResolver,
    {
      provide: PageMetaResolver,
      useExisting: CustomPageMetaResolver,
      multi: true,
    },
    provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<OccConfig>{
      backend: {
        occ: {
          //baseUrl: 'https://qcc6-backoffice-ext.np-shop.boeing.com/',
        // baseUrl: environment.baseOCCUrl,
          prefix: '/bgswebservices/v2/'
        }
      },
    }), provideConfig(<SiteContextConfig>{
      context: {
        urlParameters: [],
        baseSite: ['myboeingfleet'],
        currency: ['USD'],
        language: ['en_US'],
      },
    }), provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }), provideConfig(<FeaturesConfig>{
      features: {
        evel: '2211.21'
      }
    }),
    provideConfig({
      pwa: {
        enabled: true,
        addToHomeScreen: true,
      },
    }),
    provideConfig({
      cmsComponents: {

      }
    }),

    provideConfig({
      layoutSlots: {
        header: {
          lg: { slots: ['TopHeaderSlot'] },
          slots: ['TopHeaderSlot'],
        },
        // navigation: {
        //   lg: { slots: ['NavigationBar'] },
        //   slots: ['NavigationBar'],
        // },
        breadcrumb: {
          lg: { slots: ['Breadcrumb'] },
          slots: ['Breadcrumb'],
        },
        LandingPage2Template: {
          pageFold: 'Section2B',
          slots: [
            'NavigationBar',
            'BottomHeaderSlot',
            'Breadcrumb',
            'Section1',
            'Section2',
            'Section3',
            'Section7',
            'Section4',
            'Section8',
            'Section5',
            'Section6',
            'Section9'
          ],
        },
        MarketingLandingPageTemplate: {
          slots: [
            'NavigationBar',
            'BottomHeaderSlot',
            'Breadcrumb',
            'Section1',
            'Section2',
            'Section3',
            'Section4',
            'Section5',
            'Section6',
            'Section7',
            'Section8'
          ],
        },
        BoeingFleetSearchResultsListPageTemplate: {
          slots: [
            'NavigationBar',
            'BottomHeaderSlot',
            'Breadcrumb',
            'BoeingFleetSearchResultsListSlot'
          ]
        },
        BoeingFleetNewsPageTemplate: {
          slots: [
            'NavigationBar',
            'BottomHeaderSlot',
            'Breadcrumb',
            'Section1',
            'BoeingFleetNewsHeaderSlot',
            'Section2',
            'BoeingFleetArticleListSlot',
            'Section3'
          ]
        },
        ArticlePageTemplate: {
          slots: [
            'NavigationBar',
            'BottomHeaderSlot',
            'Breadcrumb',
            'ArticleInfo',
            'Section1',
            'Section2',
            'Section3',
            'Section4',
            'Section5',
            'Section6',
            'Section7',
            'Section8',
            'ArticleInfoBottom'
          ]
        },
        BoeingFleetEventsPageTemplate: {
          slots: [
            'NavigationBar',
            'BottomHeaderSlot',
            'Breadcrumb',
            'Section1',
            'BoeingFleetEventsHeaderSlot',
            'BoeingFleetEventsListSlot',
            'Section2',
            'Section3']
        },
        WebinarsListingPageTemplate: {
          slots: [
            'Section1',
            'WebinarsSpotlightSlot',
            'WebinarsListingSlot',
            'Section2',
            'Section3']
        },
        WebinarLandingPageTemplate: {
          slots: [
            'WebinarInfo',
            'Section1',
            'PlaceholderContentSlot',
            'Section2',
            'Section3']
        },
        footer: {
          lg: { slots: ['Footer'] },
          slots: ['Footer'],
        },
      }
    } as LayoutConfig),
    provideConfig(defaultB2bOccConfig)]
})
export class SpartacusConfigurationModule { }