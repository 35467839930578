var wishlist = {
	empty: "No products in your wish list yet",
	itemRemoved: "Selected item has been removed.",
	caption: "Wish list contents."
};
var wishlist$1 = {
	wishlist: wishlist
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    wishlist: wishlist$1,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const wishListTranslations = {
    en,
};
const wishListTranslationChunksConfig = {
    wishlist: ['wishlist'],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { wishListTranslationChunksConfig, wishListTranslations };

