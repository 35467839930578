var storeFinder = {
	openUntil: "Open until",
	closed: "Closed",
	back: "Back",
	call: "Call",
	getDirections: "Get Directions",
	ariaLabelGetDirections: "Get Directions, this link opens in a new window",
	ariaLabelCountriesCount: "Stores in {{ count }} countries found",
	listView: "List View",
	mapView: "Map View",
	noStoresFound: "No Stores Found.",
	storeHours: "Store hours",
	storeFeatures: "Store features",
	storeFinder: "Store Finder",
	storesFoundTitle: "Stores Found",
	storesFound: "{{ count }} store found",
	storesFound_other: "{{ count }} stores found",
	fromStoresFound: "from {{ count }} store found",
	fromStoresFound_other: "from {{ count }} stores found",
	findStore: "Find store",
	findStoreBy: "Find store by",
	useMyLocation: "Use my location",
	viewAllStores: "View all stores",
	contactUs: "Contact us",
	searchBox: "Postal code or town or address",
	backToList: "Back to list",
	noStoresMessage: "No stores found in database...",
	geolocationNotEnabled: "Cannot locate nearby stores. Geolocation consent has not been enabled",
	searchNearestStores: "Search nearest stores",
	searchBoxLabel: "Enter postal code, town or address"
};
var storeFinder$1 = {
	storeFinder: storeFinder
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    storeFinder: storeFinder$1,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const storeFinderTranslations = {
    en,
};
const storeFinderTranslationChunksConfig = {
    storeFinder: ['storeFinder'],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { storeFinderTranslationChunksConfig, storeFinderTranslations };

