import * as i0 from '@angular/core';
import { Injectable, Directive, Component, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
const _c0 = ["*"];
const defaultOptions = {
  htmlType: 'domObj',
  printMode: 'template',
  pageTitle: '',
  templateString: '',
  popupProperties: '',
  stylesheets: [],
  styles: [],
  baseHref: ''
};
/**
 * HEAD Element
 * @returns
 */
const HEAD = () => {
  const head = document.getElementsByTagName('head')[0];
  const ID = Math.round(Math.random() * 9999999).toString();
  const nameUID = `ngx-print-element-${ID}`;
  const nameUIDHead = `ngx-print-element-head-${ID}`;
  const nameUIDBody = `ngx-print-element-body-${ID}`;
  const nameUIDIFrame = `ngx-print-element-iframe-${ID}`;
  return {
    head,
    nameUID,
    nameUIDHead,
    nameUIDBody,
    nameUIDIFrame
  };
};
/**
 * ADD_STYLE_HEAD
 * @param _H
 */
const ADD_STYLE_HEAD = _H => {
  const style = document.createElement('style');
  style.innerHTML = BUILD_STYLE(_H);
  style.id = `${_H.nameUIDHead}`;
  _H.head.appendChild(style);
};
/**
 * BUILD_STYLE
 * @param _H
 * @returns
 */
const BUILD_STYLE = _H => {
  return `
  @media print {
    .${_H.nameUIDBody} {
      visibility: hidden !important;
    }
    .${_H.nameUID},
    .${_H.nameUID} * {
        visibility: visible !important;
    }
    .${_H.nameUID} {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
    }
    .${_H.nameUID} .print-none {
        display: none !important;
    }
    /* ----- Author: DaiDH ----- */
    /* Copyright (C) 2022 - ${new Date().getFullYear()} */
  }`;
};
/**
 * REMOVE_STYLE_HEAD
 * @param _H
 */
const REMOVE_STYLE_HEAD = _H => {
  const styleToRemove = document.getElementById(_H.nameUIDHead);
  if (styleToRemove) {
    _H.head.removeChild(styleToRemove);
  }
};
/**
 * getBaseHref
 * @param options
 * @returns
 */
const getBaseHref = options => {
  const port = window.location.port ? `:${window.location.port}` : '';
  const buildURL = `${window.location.protocol}//${window.location.hostname}${port}${window.location.pathname}`;
  const finalURL = options.baseHref ? options.baseHref : buildURL;
  return finalURL;
};
/**
 * getMarkup
 * print-html-element
 * @param element
 * @param options
 * @param _H
 * @returns
 */
const getMarkup = (element, options, _H) => {
  const template = options.templateString;
  const templateRegex = new RegExp(/{{\s*printBody\s*}}/gi);
  let stylesheets;
  let styles;
  const html = [];
  if (options.htmlType === 'domObj') {
    element = element.outerHTML;
  }
  if (options.htmlType === 'text') {
    element = element.innerText;
  }
  if (template && templateRegex.test(template)) {
    element = template.replace(templateRegex, element);
  }
  html.push(`<html><head><title>${options.pageTitle || ''}</title>`);
  // If stylesheet URL's or list of stylesheet URL's are specified, override page stylesheets
  if (options.stylesheets) {
    stylesheets = Array.isArray(options.stylesheets) ? options.stylesheets : [options.stylesheets];
  } else {
    stylesheets = Array.prototype.slice.call(document.getElementsByTagName('link')).map(link => link);
  }
  stylesheets.forEach(f => {
    html.push(`<link rel="${f.rel}" href="${f.href}">`);
  });
  // If inline styles or list of inline styles are specified, override inline styles
  if (options.styles) {
    styles = Array.isArray(options.styles) ? [...options.styles, BUILD_STYLE(_H)] : [options.styles + BUILD_STYLE(_H)];
  } else {
    styles = Array.prototype.slice.call(document.getElementsByTagName('style')).map(style => style.innerHTML);
  }
  styles.forEach(style => {
    html.push(`<style type="text/css">${style}</style>`);
  });
  html.push(`<base href="${getBaseHref(options)}"/>`);
  html.push(`</head><body class="${_H.nameUID}">`);
  html.push(element);
  html.push('</body></html>');
  return html.join('');
};
/**
 * Print window.open
 * @param element
 * @param selfOptions
 * @param as
 */
const printElementWindow = (element, selfOptions = {}, as) => {
  try {
    const options = {
      ...defaultOptions,
      ...selfOptions
    };
    const container = element.nativeElement;
    const _H = HEAD();
    // New window
    const printWindow = window.open('about:blank', 'printElementWindow', options.popupProperties);
    const printDocument = printWindow && printWindow.document;
    // Get markup to be printed
    const markup = getMarkup(container, options, _H);
    ADD_STYLE_HEAD(_H);
    // Close
    const onPrintFinished = printed => {
      printDocument.close();
      printWindow.close();
      REMOVE_STYLE_HEAD(_H);
      AS_COMPLETE(as, {
        close: true
      });
    };
    // Print
    printWindow.focus();
    printDocument.write(markup);
    setTimeout(() => onPrintFinished(printWindow.print()), selfOptions && selfOptions.windowOpenTimeout || 500);
    printWindow.onbeforeprint = event => AS_COMPLETE(as, event);
    printWindow.onafterprint = event => AS_COMPLETE(as, event);
  } catch (error) {
    AS_COMPLETE(as, error);
  }
};
/**
 * Print iFrame
 * @param element
 * @param selfOptions
 * @param as
 */
const printIFrame = (element, selfOptions = {}, as) => {
  try {
    // Declare
    const options = {
      ...defaultOptions,
      ...selfOptions
    };
    const container = element.nativeElement;
    const _H = HEAD();
    // Get markup to be printed
    const markup = getMarkup(container, options, _H);
    // Create iframe
    let iframe = document.createElement('iframe');
    iframe.setAttribute('id', _H.nameUIDIFrame);
    iframe.setAttribute('class', _H.nameUIDIFrame);
    iframe.setAttribute('src', 'about:blank');
    iframe.setAttribute('frameBorder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('style', 'position:fixed;bottom:100%;right:100%;');
    document.body.appendChild(iframe);
    let iDocument = null;
    if (iframe.contentDocument) {
      iDocument = iframe.contentDocument;
    } else if (iframe.contentWindow) {
      iDocument = iframe.contentWindow.document;
    }
    ADD_STYLE_HEAD(_H);
    // SetTimeout fixesiframe printMode does not work in firefox
    setTimeout(() => {
      // Close
      const onPrintFinished = printed => {
        iDocument.close();
        REMOVE_STYLE_HEAD(_H);
        iframe.remove();
        AS_COMPLETE(as, {
          close: true
        });
      };
      // Print
      iframe.contentWindow.focus();
      iDocument.open();
      iDocument.write(markup);
      setTimeout(() => onPrintFinished(iframe.contentWindow.print()), selfOptions && selfOptions.windowOpenTimeout || 200);
      iframe.contentWindow.onbeforeprint = event => AS_COMPLETE(as, event);
      iframe.contentWindow.onafterprint = event => AS_COMPLETE(as, event);
    });
  } catch (error) {
    AS_COMPLETE(as, error);
  }
};
/**
 * Print default
 * @param element
 * @param renderer
 * @param as
 */
const printDefault = (element, renderer, selfOptions = {}, as) => {
  try {
    // Declare
    const container = element.nativeElement;
    const _H = HEAD();
    // Add visibility hidden into body
    const bodyEls = Array.from(document.querySelectorAll('body'));
    if (bodyEls.length) {
      bodyEls.forEach(f => f && f.classList.add(_H.nameUIDBody));
      renderer.addClass(container, _H.nameUID);
      ADD_STYLE_HEAD(_H);
    }
    // Close
    const onPrintFinished = printed => {
      // Clear visibility: hidden
      bodyEls.forEach(f => f && f.classList.remove(_H.nameUIDBody));
      renderer.setAttribute(container, 'class', container.className.replace(_H.nameUID, ''));
      REMOVE_STYLE_HEAD(_H);
      AS_COMPLETE(as, {
        close: true
      });
    };
    // Print
    window.focus();
    setTimeout(() => onPrintFinished(window.print()), selfOptions && selfOptions.windowOpenTimeout || 0);
    window.onbeforeprint = event => AS_COMPLETE(as, event);
    window.onafterprint = event => AS_COMPLETE(as, event);
  } catch (error) {
    AS_COMPLETE(as, error);
  }
};
/**
 * Rxjs complete
 * @param as
 * @param data
 * @param error
 */
const AS_COMPLETE = (as, data, error = null) => {
  error ? as.error(error) : as.next(data);
};
class NgxPrintElementService {
  /**
   * Print
   * @param element
   * @param config
   * @returns
   */
  print(element, config) {
    const as = new Subject();
    // Create and insert new print section
    if (element) {
      // Element
      if (config) {
        // Config
        if (config.printMode === 'template') {
          // iframe
          printIFrame(element, config, as);
        }
        if (config.printMode === 'template-popup') {
          // windows
          printElementWindow(element, config, as);
        }
      } else {
        // No config
        printDefault(element, this.renderer, config, as);
      }
    }
    return as;
  }
  static {
    this.ɵfac = function NgxPrintElementService_Factory(t) {
      return new (t || NgxPrintElementService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxPrintElementService,
      factory: NgxPrintElementService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrintElementService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class NgxPrintElementDirective {
  constructor(renderer, prints) {
    this.renderer = renderer;
    this.prints = prints;
  }
  ngOnInit() {
    this.prints.renderer = this.renderer;
  }
  static {
    this.ɵfac = function NgxPrintElementDirective_Factory(t) {
      return new (t || NgxPrintElementDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(NgxPrintElementService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxPrintElementDirective,
      selectors: [["", "ngxPrintElement", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrintElementDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxPrintElement]'
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: NgxPrintElementService
    }];
  }, null);
})();
class NgxPrintElementComponent {
  constructor(prints) {
    this.prints = prints;
  }
  /**
   * Print
   * @param elementRef
   * @param config
   * @returns
   */
  print(elementRef, config) {
    return this.prints.print(elementRef, config);
  }
  static {
    this.ɵfac = function NgxPrintElementComponent_Factory(t) {
      return new (t || NgxPrintElementComponent)(i0.ɵɵdirectiveInject(NgxPrintElementService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxPrintElementComponent,
      selectors: [["ngx-print-element"]],
      exportAs: ["element"],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function NgxPrintElementComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrintElementComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-print-element',
      template: `<ng-content></ng-content>`,
      exportAs: 'element'
    }]
  }], function () {
    return [{
      type: NgxPrintElementService
    }];
  }, null);
})();
class NgxPrintElementModule {
  static {
    this.ɵfac = function NgxPrintElementModule_Factory(t) {
      return new (t || NgxPrintElementModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxPrintElementModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxPrintElementModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxPrintElementComponent, NgxPrintElementDirective],
      exports: [NgxPrintElementComponent, NgxPrintElementDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-print-element
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPrintElementComponent, NgxPrintElementDirective, NgxPrintElementModule, NgxPrintElementService };
