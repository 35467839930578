{
  "name": "@bdhm/bgs-common-ui-lib",
  "version": "1.1.597-5",
  "peerDependencies": {
    "@angular/common": "^17.3.4",
    "@angular/core": "^17.3.4"
  },
  "dependencies": {
    "tslib": "^2.6.2"
  },
  "publishConfig": {
    "@bdhm:registry": "https://gitlab-ext.digitalaviationservices.com/api/v4/projects/6709/packages/npm/"
  },
  "module": "fesm2022/bdhm-bgs-common-ui-lib.mjs",
  "typings": "index.d.ts",
  "exports": {
    "./package.json": {
      "default": "./package.json"
    },
    ".": {
      "types": "./index.d.ts",
      "esm2022": "./esm2022/bdhm-bgs-common-ui-lib.mjs",
      "esm": "./esm2022/bdhm-bgs-common-ui-lib.mjs",
      "default": "./fesm2022/bdhm-bgs-common-ui-lib.mjs"
    }
  },
  "sideEffects": false
}