/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const myAccountV2UserProfile = {
    myAccountV2UserProfile: {
        myEmailAddress: 'My Email Address',
        myInformation: 'My Information',
        name: 'Name',
        customerId: 'CustomerId',
        title: 'Title',
        firstName: 'First name',
        lastName: 'Last name',
    },
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const myAccountV2Email = {
    myAccountV2Email: {
        myEmailAddress: 'My Email Address',
        email: 'Email',
        emailAddress: 'Email Address',
        newEmailAddress: 'New Email Address',
        confirmNewEmailAddress: 'Confirm New Email Address',
        emailPlaceHolder: 'Enter email',
        passwordPlaceHolder: 'Enter password',
        password: 'Password',
        reloginIndicator: 'You need to log in again after setting a new email address.',
    },
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const myAccountV2Password = {
    myAccountV2PasswordForm: {
        oldPassword: {
            label: 'Old Password',
            placeholder: 'Enter Old Password',
        },
        oldPasswordIsRequired: 'Old password is required.',
        newPassword: {
            label: 'New Password',
            placeholder: 'Enter New Password',
        },
        passwordMinRequirements: 'Password must be six characters minimum, with one uppercase letter, one number, one symbol',
        confirmPassword: {
            label: 'Confirm New Password',
            placeholder: 'Confirm New Password',
        },
        reloginIndicator: 'You need to log in again after setting password.',
        bothPasswordMustMatch: 'Both password must match',
        passwordUpdateSuccess: 'Password updated with success',
        accessDeniedError: 'Access is denied',
        newPasswordTitle: 'My Password',
    },
};

var updateEmailForm = {
	newEmailAddress: {
		label: "New email address",
		placeholder: "Enter email"
	},
	confirmNewEmailAddress: {
		label: "Confirm new email address",
		placeholder: "Enter email"
	},
	enterValidEmail: "Please enter a valid email.",
	bothEmailMustMatch: "Both emails must match",
	password: {
		label: "Password",
		placeholder: "Enter password"
	},
	pleaseInputPassword: "Please input password",
	emailUpdateSuccess: "Success. Please sign in with {{ newUid }}"
};
var register = {
	confirmPassword: {
		action: "Confirm password",
		label: "Confirm password",
		placeholder: "Confirm Password"
	},
	managementInMyAccount: "Management in My Account.",
	termsAndConditions: "Terms & Conditions",
	signIn: "I already have an account. Sign In",
	register: "Register",
	confirmNewPassword: "Confirm New Password",
	resetPassword: "Reset Password",
	createAccount: "Create an account",
	title: "Title",
	titleOptional: "Title (optional)",
	firstName: {
		label: "First name",
		placeholder: "First name"
	},
	lastName: {
		label: "Last name",
		placeholder: "Last name"
	},
	emailAddress: {
		label: "Email address",
		placeholder: "Email address"
	},
	password: {
		label: "Password",
		placeholder: "Password"
	},
	newPassword: "New Password",
	emailMarketing: "Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.",
	confirmThatRead: "I am confirming that I have read and agreed with the",
	selectTitle: "Select Title",
	passwordMinRequirements: "Here's a quick guide for a secure password: Use at least 8 characters with a mix of upper and lowercase letters, numbers, and symbols.",
	passwordStrengthRequirements: "Here's a quick guide for a secure password: Use at least 8 characters with a mix of upper and lowercase letters, numbers, and symbols. Make sure not to use the same character consecutively.",
	bothPasswordMustMatch: "Both password must match",
	titleRequired: "Title is required.",
	postRegisterMessage: "Please log in with provided credentials.",
	postRegisterSuccessMessage: "Successful Registration: Please log in with provided credentials"
};
var forgottenPassword = {
	resetPassword: "Reset password",
	enterEmailAddressAssociatedWithYourAccount: "Enter the email address associated with your account",
	emailAddress: {
		label: "Email address",
		placeholder: "Enter email"
	},
	enterValidEmail: "Please enter a valid email.",
	passwordResetEmailSent: "An email has been sent to you with information on how to reset your password.",
	passwordResetSuccess: "Success! You can now login using your new password."
};
var userProfile = {
	updateEmailForm: updateEmailForm,
	register: register,
	forgottenPassword: forgottenPassword
};

var addressForm = {
	title: "Title",
	titleOptional: "Title (optional)",
	defaultTitle: "Title",
	firstName: {
		label: "First name",
		placeholder: "First Name"
	},
	lastName: {
		label: "Last name",
		placeholder: "Last Name"
	},
	address1: "Address 1",
	address2: "Address 2 (optional)",
	country: "Country/Region",
	city: {
		label: "City",
		placeholder: "City"
	},
	state: "State",
	zipCode: {
		label: "Zip code",
		placeholder: "Postal Code/Zip"
	},
	phoneNumber: {
		label: "Phone number (optional)",
		placeholder: "e.g. (###) - ### - ####"
	},
	cellphone: {
		label: "Mobile number (optional)",
		placeholder: "e.g. (###) - ### - ####"
	},
	saveAsDefault: "Save as default",
	chooseAddress: "Choose address",
	streetAddress: "Street Address",
	aptSuite: "Apartment Number",
	selectOne: "Select One...",
	setAsDefault: "Set as default delivery address",
	titleRequired: "Title is required.",
	userAddressAddSuccess: "New address was added successfully!",
	userAddressUpdateSuccess: "Address updated successfully!",
	userAddressDeleteSuccess: "Address deleted successfully!",
	invalidAddress: "Invalid Address"
};
var addressBook = {
	addNewDeliveryAddress: "Add a new delivery address",
	editDeliveryAddress: "Edit delivery address",
	areYouSureToDeleteAddress: "Are you sure you want to delete this address?",
	addNewAddress: "Add new address",
	addAddress: "Add address",
	updateAddress: "Update address",
	backToAddressList: "Back to address list",
	defaultDeliveryAddress: "Default Delivery Address",
	additionalDeliveryAddress: "Additional Delivery Address {{number}}"
};
var addressCard = {
	"default": "DEFAULT",
	selected: "Selected",
	selectedAddress: "Selected Address",
	setAsDefault: "Set as default",
	shipTo: "Ship To",
	billTo: "Bill To",
	phoneNumber: "P",
	mobileNumber: "M"
};
var addressSuggestion = {
	verifyYourAddress: "Verify your address",
	ensureAccuracySuggestChange: "To ensure delivery accuracy, we suggest the change selected below.",
	chooseAddressToUse: "Please choose which address you would like to use:",
	suggestedAddress: "Suggested address",
	enteredAddress: "Entered address",
	editAddress: "Edit address",
	saveAddress: "Save address"
};
var addressMessages = {
	setAsDefaultSuccessfully: "Address {{ streetAddress }} was successfully set as default"
};
var address = {
	addressForm: addressForm,
	addressBook: addressBook,
	addressCard: addressCard,
	addressSuggestion: addressSuggestion,
	addressMessages: addressMessages
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    userProfile,
    myAccountV2UserProfile,
    myAccountV2Email,
    myAccountV2Password,
    address,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const userProfileTranslations = {
    en,
};
const userProfileTranslationChunksConfig = {
    userProfile: ['updateEmailForm', 'register', 'forgottenPassword'],
    myAccountV2UserProfile: ['myAccountV2UserProfile'],
    myAccountV2Email: ['myAccountV2Email'],
    myAccountV2Password: ['myAccountV2PasswordForm'],
    address: [
        'addressForm',
        'addressBook',
        'addressCard',
        'addressSuggestion',
        'addressMessages',
    ],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { userProfileTranslationChunksConfig, userProfileTranslations };

