import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Optional, Input, NgModule } from '@angular/core';
import * as i1 from '@spartacus/core';
import { VariantQualifier, UrlModule, I18nModule, provideDefaultConfigFactory } from '@spartacus/core';
import * as i2 from '@spartacus/storefront';
import { ProductListOutlets, provideOutlet, OutletPosition } from '@spartacus/storefront';
import { Subscription, EMPTY } from 'rxjs';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function ProductVariantStyleIconsComponent_ul_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelement(1, "img");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const v_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵattribute("src", ctx_r1.getVariantThumbnailUrl(v_r1.variantOptionQualifiers), i0.ɵɵsanitizeUrl)("title", ctx_r1.variantNames[v_r1.code])("alt", ctx_r1.variantNames[v_r1.code]);
  }
}
function ProductVariantStyleIconsComponent_ul_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ul", 1);
    i0.ɵɵtemplate(1, ProductVariantStyleIconsComponent_ul_0_li_1_Template, 2, 3, "li", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.variants);
  }
}
const PRODUCT_VARIANTS_FEATURE = 'productVariants';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantStyleIconsComponent {
  constructor(config, productListItemContext) {
    this.config = config;
    this.productListItemContext = productListItemContext;
    this.subscriptions = new Subscription();
    this.ProductListOutlets = ProductListOutlets;
    this.product$ = this.productListItemContext?.product$ ?? EMPTY;
    this.variantNames = {};
  }
  ngOnInit() {
    this.setVariantsNames();
    this.subscriptions.add(this.product$.subscribe(product => {
      if (product.variantOptions && product.variantOptions.length) {
        this.variants = product.variantOptions;
        this.setVariantsNames();
      }
    }));
  }
  setVariantsNames() {
    this.variants?.forEach(variant => {
      if (variant.code && variant.variantOptionQualifiers) {
        this.variantNames[variant.code] = this.getVariantName(variant.variantOptionQualifiers) || '';
      }
    });
  }
  getVariantThumbnailUrl(variantOptionQualifiers) {
    const thumbnail = variantOptionQualifiers.find(item => item.qualifier === VariantQualifier.THUMBNAIL);
    return thumbnail ? `${this.config?.backend?.occ?.baseUrl}${thumbnail.image?.url}` : '';
  }
  getVariantName(variantOptionQualifiers) {
    const rollupProperty = variantOptionQualifiers.find(item => item.qualifier === VariantQualifier.ROLLUP_PROPERTY);
    const property = rollupProperty ? variantOptionQualifiers.find(item => item.qualifier === rollupProperty.value) : null;
    return property ? property.value : '';
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  static {
    this.ɵfac = function ProductVariantStyleIconsComponent_Factory(t) {
      return new (t || ProductVariantStyleIconsComponent)(i0.ɵɵdirectiveInject(i1.OccConfig), i0.ɵɵdirectiveInject(i2.ProductListItemContext, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProductVariantStyleIconsComponent,
      selectors: [["cx-variant-style-icons"]],
      inputs: {
        variants: "variants"
      },
      decls: 1,
      vars: 1,
      consts: [["class", "variant-list", 4, "ngIf"], [1, "variant-list"], [4, "ngFor", "ngForOf"]],
      template: function ProductVariantStyleIconsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ProductVariantStyleIconsComponent_ul_0_Template, 2, 1, "ul", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.variants && ctx.variants.length);
        }
      },
      dependencies: [i3.NgForOf, i3.NgIf],
      styles: ["ul[_ngcontent-%COMP%]{padding:0;white-space:nowrap;overflow:hidden}ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{display:inline}ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:50px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantStyleIconsComponent, [{
    type: Component,
    args: [{
      selector: 'cx-variant-style-icons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ul class=\"variant-list\" *ngIf=\"variants && variants.length\">\n  <li *ngFor=\"let v of variants\">\n    <img\n      [attr.src]=\"getVariantThumbnailUrl(v.variantOptionQualifiers)\"\n      [attr.title]=\"variantNames[v.code]\"\n      [attr.alt]=\"variantNames[v.code]\"\n    />\n  </li>\n</ul>\n",
      styles: ["ul{padding:0;white-space:nowrap;overflow:hidden}ul li{display:inline}ul li img{width:50px}\n"]
    }]
  }], () => [{
    type: i1.OccConfig
  }, {
    type: i2.ProductListItemContext,
    decorators: [{
      type: Optional
    }]
  }], {
    variants: [{
      type: Input
    }]
  });
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantStyleIconsModule {
  static {
    this.ɵfac = function ProductVariantStyleIconsModule_Factory(t) {
      return new (t || ProductVariantStyleIconsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantStyleIconsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, UrlModule, I18nModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantStyleIconsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, UrlModule, I18nModule],
      declarations: [ProductVariantStyleIconsComponent],
      exports: [ProductVariantStyleIconsComponent]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultProductVariantsComponentsConfig() {
  const config = {
    featureModules: {
      [PRODUCT_VARIANTS_FEATURE]: {
        cmsComponents: ['ProductVariantSelectorComponent']
      }
    }
  };
  return config;
}
class ProductVariantsRootModule {
  static {
    this.ɵfac = function ProductVariantsRootModule_Factory(t) {
      return new (t || ProductVariantsRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantsRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultProductVariantsComponentsConfig), provideOutlet({
        id: ProductListOutlets.ITEM_DETAILS,
        position: OutletPosition.AFTER,
        component: ProductVariantStyleIconsComponent
      })],
      imports: [ProductVariantStyleIconsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantsRootModule, [{
    type: NgModule,
    args: [{
      imports: [ProductVariantStyleIconsModule],
      providers: [provideDefaultConfigFactory(defaultProductVariantsComponentsConfig), provideOutlet({
        id: ProductListOutlets.ITEM_DETAILS,
        position: OutletPosition.AFTER,
        component: ProductVariantStyleIconsComponent
      })]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { PRODUCT_VARIANTS_FEATURE, ProductVariantStyleIconsComponent, ProductVariantStyleIconsModule, ProductVariantsRootModule, defaultProductVariantsComponentsConfig };
