var checkout = {
	backToCart: "Back to cart"
};
var checkoutProgress = {
	label: "Checkout Progress",
	deliveryAddress: "Shipping Address",
	deliveryMode: "Delivery Mode",
	paymentDetails: "Payment",
	reviewOrder: "Review",
	state: {
		completed: "{{step}}, Completed",
		selected: "{{step}}, Selected",
		disabled: "{{step}}, Disabled"
	}
};
var checkoutAddress = {
	shippingAddress: "Shipping Address",
	selectYourDeliveryAddress: "Select your Delivery Address",
	defaultDeliveryAddress: "Default Delivery Address",
	addNewAddress: "Add New Address",
	shipToThisAddress: "Ship to this address",
	deliveryAddressSelected: "Delivery address selected"
};
var checkoutMode = {
	deliveryMethod: "Delivery Method",
	deliveryOptions: "Delivery Options",
	standardDelivery: "Standard Delivery",
	premiumDelivery: "Premium Delivery",
	deliveryEntries: "Items to be Shipped"
};
var checkoutReview = {
	review: "Review",
	reviewOrder: "Review Order",
	orderItems: "Order Items",
	confirmThatRead: "I am confirming that I have read and agreed with the",
	placeOrder: "Place Order",
	termsAndConditions: "Terms & Conditions",
	editDeliveryAddressDetails: "Edit delivery address details, opens Delivery Address page",
	editBillingDetails: "Edit billing address, opens Payment Details page",
	editPaymentDetails: "Edit payment details, opens Payment Details page",
	editPaymentType: "Edit payment method, opens Method of Payment page",
	editDeliveryMode: "Edit delivery mode, opens Delivery Mode page",
	orderInProcess: "Order is in process. Please wait."
};
var checkoutOrderConfirmation = {
	confirmationOfOrder: "Confirmation of Order:",
	thankYou: "Thank you for your order!",
	invoiceHasBeenSentByEmail: "An invoice has been sent by email. You should receive it soon.",
	orderItems: "Order Items",
	orderPlacedSuccessfully: "Order placed successfully",
	createAccount: "Create an account?",
	createAccountForNext: "Create an account for <{{email}}> for a faster checkout on your next visit."
};
var checkout$1 = {
	checkout: checkout,
	checkoutProgress: checkoutProgress,
	checkoutAddress: checkoutAddress,
	checkoutMode: checkoutMode,
	checkoutReview: checkoutReview,
	checkoutOrderConfirmation: checkoutOrderConfirmation
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    checkout: checkout$1,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const checkoutTranslations = {
    en,
};
const checkoutTranslationChunksConfig = {
    checkout: [
        'checkout',
        'checkoutProgress',
        'checkoutAddress',
        'checkoutMode',
        'checkoutReview',
        'checkoutOrderConfirmation',
    ],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { checkoutTranslationChunksConfig, checkoutTranslations };

