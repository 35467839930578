import * as i0 from '@angular/core';
import { Injectable, inject, isDevMode, APP_INITIALIZER, NgModule } from '@angular/core';
import * as i1 from '@spartacus/core';
import { Config, LoggerService } from '@spartacus/core';
import { Subscription, merge } from 'rxjs';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * TMS configuration
 */
class TmsConfig {
  static {
    this.ɵfac = function TmsConfig_Factory(t) {
      return new (t || TmsConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TmsConfig,
      factory: function TmsConfig_Factory(t) {
        let r = null;
        if (t) {
          r = new (t || TmsConfig)();
        } else {
          r = i0.ɵɵinject(Config);
        }
        return r;
      },
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TmsConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useExisting: Config
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * This service interacts with the configured data layer object by pushing the Spartacus events to it.
 */
class TmsService {
  constructor(eventsService, windowRef, tmsConfig, injector) {
    this.eventsService = eventsService;
    this.windowRef = windowRef;
    this.tmsConfig = tmsConfig;
    this.injector = injector;
    /**
     * Stores subscriptions to events.
     */
    this.subscription = new Subscription();
    this.logger = inject(LoggerService);
  }
  /**
   * Called only once to start collecting and dispatching events
   */
  collect() {
    if (!this.windowRef.isBrowser()) {
      return;
    }
    for (const tmsCollectorConfig in this.tmsConfig.tagManager) {
      if (!this.tmsConfig.tagManager?.hasOwnProperty(tmsCollectorConfig)) {
        continue;
      }
      const collectorConfig = this.tmsConfig.tagManager[tmsCollectorConfig] ?? {};
      if (!collectorConfig.collector) {
        if (isDevMode()) {
          this.logger.warn(`Skipping the '${tmsCollectorConfig}', as the collector is not defined.`);
        }
        continue;
      }
      const events = collectorConfig.events?.map(event => this.eventsService.get(event)) || [];
      const collector = this.injector.get(collectorConfig.collector);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      collector.init(collectorConfig, this.windowRef.nativeWindow);
      this.subscription.add(this.mapEvents(events).subscribe(event => {
        if (collectorConfig.debug) {
          this.logger.log(`🎤 Pushing the following event to ${tmsCollectorConfig}: `, event);
        }
        event = collector.map?.(event) ?? event;
        collector.pushEvent(collectorConfig,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.windowRef.nativeWindow, event);
      }));
    }
  }
  /**
   * Maps the given events to an appropriate type that fits the specified TMS' structure.
   *
   * @param events - the events to map
   * @param collector - a name of the collector for which the events should be mapped
   */
  mapEvents(events) {
    return merge(...events);
  }
  /**
   * Angular's callback
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  static {
    this.ɵfac = function TmsService_Factory(t) {
      return new (t || TmsService)(i0.ɵɵinject(i1.EventService), i0.ɵɵinject(i1.WindowRef), i0.ɵɵinject(TmsConfig), i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TmsService,
      factory: TmsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TmsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.EventService
  }, {
    type: i1.WindowRef
  }, {
    type: TmsConfig
  }, {
    type: i0.Injector
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * The factory that conditionally (based on the configuration) starts collecting events
 */
function tmsFactory(service) {
  const result = () => service.collect();
  return result;
}
class BaseTmsModule {
  static forRoot() {
    return {
      ngModule: BaseTmsModule,
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: tmsFactory,
        deps: [TmsService],
        multi: true
      }]
    };
  }
  static {
    this.ɵfac = function BaseTmsModule_Factory(t) {
      return new (t || BaseTmsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BaseTmsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseTmsModule, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/** AUGMENTABLE_TYPES_END */

/**
 * Generated bundle index. Do not edit.
 */

export { BaseTmsModule, TmsConfig, TmsService, tmsFactory };
